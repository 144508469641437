<template>

  <div class="form-pagination">
    <span v-for="x in total" :key="x" 
       v-bind:class="[(x === index) ? 'form-pagination-bullet-active' : '', 'form-pagination-bullet']"></span>
  </div>

</template>

<script>

  export default {
    name: 'PaginationComponent',
    props: {
      index: Number,
      total: Number
    }
  }

</script>