<template>
  <div v-if="activeStep === index"
       class="form-element form-element-subscribe-2 form-element-type-subscribe form-group">
    <div class="custom-subscribe">
      <h2>{{ step.title }}</h2>
      <div class="row">
        <div v-if="imageUrl"
             class="col-sm-6 order-md-2 order-sm-2">
          <img :src="imageUrl"
               alt="">
        </div>
        <div :class="imageUrl ? 'col-sm-6 order-md-1 order-sm-1' : 'col'">
          <p>{{ step.subtitle }}</p>
          <div class="form-group">
            <input required="required"
                   :placeholder="step.input.items[0].placeholder"
                   class="form-control"
                   type="text"
                   @blur="validateEmail"
                   v-model="email">
            <small v-if="isValid === false"
                   id="passwordHelp"
                   class="text-danger">
              Skal være en gyldig e-mail-adresse.
            </small>
            <div class="form-check custom-checkbox mx-3 mt-3">
              <input type="checkbox"
                     name="newsletter"
                     id="subscribe"
                     v-model="checked"
                     class="checkbox custom-control-input form-check-input is-valid">
              <label for="subscribe"
                     class="custom-control-label form-check-label">{{ step.input.items[0].label }}</label>
            </div>
          </div>
          <p>{{ step.textAfter }}</p>
        </div>
      </div>
    </div>

    <nav class="form-navigation"
         data-form-navigation="">
      <div class="btn-toolbar"
           role="toolbar">
        <div class="btn-group"
             role="group">
          <span class="previous">
            <div v-if="index > 1">
              <button class="btn btn-cancel"
                      type="button"
                      @click="goBack(index)">{{ step.backLabel || 'Tilbage' }}</button>
            </div>
          </span>
          <span class="next">
            <div>
              <button class="btn btn-primary"
                      type="submit"
                      :disabled="!isValid || !checked"
                      @click="goForward(index)">{{ step.fwdLabel || 'Næste' }}</button>
            </div>
          </span>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import UserSelectionStore from '../stores/UserSelectionStore';
import axios from "axios";

export default {
  name: 'SubscribeStep',
  props: {
    step: Object,
    activeStep: Number,
    index: Number,
    total: Number
  },
  data() {
    return {
      email: null,
      checked: false,
      isValid: null,
    }
  },
  computed: {
    imageUrl: function () {
      return this.step.image ?? '';
    }
  },
  methods: {
    goBack(index) {
      this.setActiveElement(index - 1);
    },
    goForward(index) {
      this.isValid = true;

      if (!this.email) {
        return;
      }

      if (!this.validateEmail(this.email)) {
        return;
      }

      if (this.checked && this.isValid
          && this.step.input.items[0].campaignId
      ) {
        axios.get('https://bolius.dk/', {
          params: {
            eID: 'BoliusBoliusdk',
            email: encodeURIComponent(this.email),
            campaignId: this.step.input.items[0].campaignId
          }
        }).catch(error => {
          console.error('Error:', error);
        });

        UserSelectionStore.methods.addEmail(this.email);
        this.setActiveElement(index + 1);
      }
    },
    setActiveElement(val) {
      this.$emit('setActiveElement', val);
    },
    validateEmail() {
      this.isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
      return this.isValid;
    }
  }
}
</script>