<template>
  <div v-if="activeStep === index"
    class="form-element form-element-radiobutton-2 form-element-type-radiobutton form-group">
    <div class="custom-radio-multiple">
      <label for="bobaeredygtigtlokal-526100-radiobutton-2">{{ step.title }}</label>
      <p>{{ step.subtitle }}</p>
      <div v-html="step.htmlContent"></div>

      <div class="row form-check-list">

        <div v-if="step.image" class="col-md-6 order-md-2 mb-3 mb-md-0">
          <img :src="step.image"
               :alt="step.image">
        </div>

        <div :class="step.image ? 'col-md-6 order-md-1' : step.columnClasses" class="custom-radio-multiple">
          <div v-for="item in step.input.items" :key="item.value">
            <div class="custom-control custom-radio mb-2">
              <div v-if="autoSubmit">
                <input required="required" class="custom-control-input form-check-input"
                  type="radio" :value="item.value" v-model="radioValue"
                  @change="goForward(index, item.value)"
                  >
                <label class="custom-control-label"
                  @click="goForward(index, item.value)">
                  <span class="title">{{ item.title }}</span>
                  <span class="subtitle">{{ item.subtitle }}</span>
                </label>
              </div>
              <div v-else>
                <input required="required" class="custom-control-input form-check-input"
                  type="radio" :value="item.value" v-model="radioValue">
                <label class="custom-control-label">
                  <span class="title">{{ item.title }}</span>
                  <span class="subtitle">{{ item.subtitle }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>        
      </div>
      <p v-if="step.textAfter">
          {{step.textAfter}}
      </p>      
    </div>

    <nav class="form-navigation" data-form-navigation="">
      <div class="btn-toolbar" role="toolbar">
        <div class="btn-group" role="group">
          <span class="previous">
            <div v-if="index > 1">
              <button class="btn btn-cancel" type="button" 
                @click="goBack(index)">{{ step.backLabel || 'Tilbage' }}</button>
            </div>
          </span>
          <span class="next">
            <div v-if="this.radioValue">
              <button class="btn btn-primary" type="submit" 
                @click="goForward(index)">{{ step.fwdLabel || 'Næste' }}</button>
            </div>
            <div v-else>
              <button class="btn btn-primary" type="submit" 
                disabled @click="goForward(index)">{{ step.fwdLabel || 'Næste' }}</button>
            </div>
          </span>
        </div>
      </div>
    </nav>

  </div>

</template>

<script>
  import UserSelectionStore from '../stores/UserSelectionStore';
  
  export default {
    name: 'RadioStep',
    props: {
      step: Object,
      activeStep: Number,
      index: Number,
      total: Number
    },
    data (){
      return {
        radioValue: null,
      }
    },
    computed: {
      autoSubmit: function () {
        return this.step.input.autosubmit ?? false;
      }
    },
    methods: {
      goBack(index){
        this.setActiveElement(index - 1);
      },
      goForward(index, value){

        if(value && this.radioValue !== value){
          this.radioValue = value;
        }

        UserSelectionStore.methods.addSelection(this.step.id, this.radioValue);
        this.setActiveElement(index + 1);
      },
      setActiveElement(val) {
        this.$emit('setActiveElement', val);
      }
    }
  }
</script>