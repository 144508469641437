<template>    
  <div v-if="activeStep === index" 
    class="form-element form-element-checkbox-2 form-element-type-checkbox form-group">
    <div class="custom-checkbox-multiple">      
      <h2>{{ step.title }}</h2>
      <p>{{ step.subtitle }}</p>
      <div v-html="step.htmlContent"></div>
      <div class="row form-check-list">
        <div :class="step.columnClasses" v-for="item in step.input.items" :key="item.value">
          <div class="custom-control custom-checkbox">
            <input :id="item.value" required="required" class="custom-control-input form-check-input" 
              type="checkbox" :value="item.value" v-model="checkboxValues">
            <label :for="item.value" class="custom-control-label">
              <span class="title">{{ item.title }}</span>
              <span class="subtitle">{{ item.subtitle }}</span>
            </label>          
          </div>
        </div>        
      </div>
      <p v-if="step.textAfter">
          {{step.textAfter}}
      </p>
    </div>    
    <nav class="form-navigation" data-form-navigation="">
      <div class="btn-toolbar" role="toolbar">
        <div class="btn-group" role="group">
          <span class="previous">
            <div v-if="index > 1">
              <button class="btn btn-cancel" type="button" 
                @click="goBack(index)">{{ step.backLabel || 'Tilbage' }}</button>
            </div>
          </span>
          <span class="next">
            <div>
              <button class="btn btn-primary" type="submit" 
                @click="goForward(index)">{{ step.fwdLabel || 'Næste' }}</button>
            </div>            
          </span>
        </div>
      </div>
    </nav>
  </div>

</template>

<script>
  import UserSelectionStore from '../stores/UserSelectionStore';
  
  export default {
    name: 'CheckboxStep',
    props: {
      step: Object,
      activeStep: Number,
      index: Number,
      total: Number,
      columnClasses: String
    },
    data (){
      return {
        checkboxValues: []
      }
    },
    methods: {
      goBack(index){
        this.setActiveElement(index - 1);
      },
      goForward(index){                
        UserSelectionStore.methods.addSelection(this.step.id, this.checkboxValues);
        this.setActiveElement(index + 1);
      },
      setActiveElement(val) {
        this.$emit('setActiveElement', val);
      }      
    }
  }
</script>