<template>
  <div>

    <PaginationComponent 
      :index="activeStep"
      :total="steps.length"/>
          
    <div v-if="!showLoader">      
      <div v-for="(step, index) in steps" :key="index">
        <div v-if="step.input.type === 'radio'">
          <transition name="slide-fade">
            <RadioStep 
              :step="step"              
              :activeStep="activeStep"               
              :index="index + 1"
              :total="steps.length"              
              @setActiveElement="setActiveElement" />
          </transition>
        </div>
        <div v-if="step.input.type === 'checkbox'">
          <transition name="slide-fade">
            <CheckboxStep 
              :step="step"              
              :activeStep="activeStep"               
              :index="index + 1"
              :total="steps.length"               
              @setActiveElement="setActiveElement"/>
          </transition>
        </div>
        <div v-if="step.input.type === 'info'">
          <transition name="slide-fade">
            <InfoStep
                :step="step"
                :activeStep="activeStep"
                :index="index + 1"
                :total="steps.length"
                @setActiveElement="setActiveElement"/>
          </transition>
        </div>
        <div v-if="step.input.type === 'subscribe'">
          <transition name="slide-fade">
            <SubscribeStep
                :step="step"
                :activeStep="activeStep"
                :index="index + 1"
                :total="steps.length"
                @setActiveElement="setActiveElement"/>
          </transition>
        </div>
      </div>
    </div>
    <div v-else>
      <LoaderComponent/>
    </div>
  </div>
</template>

<script>
  import PaginationComponent from './PaginationComponent';
  import RadioStep from './RadioStep';
  import CheckboxStep from './CheckboxStep';
  import InfoStep from './InfoStep';
  import SubscribeStep from './SubscribeStep';
  import LoaderComponent from './LoaderComponent';
  import UserSelectionStore from '../stores/UserSelectionStore';
  import he from 'he';

  const dataLayer = window.dataLayer = window.dataLayer || [];

  export default {
    name: 'StepperComponent',
    components: {
      PaginationComponent,
      RadioStep,
      CheckboxStep,
      InfoStep,
      SubscribeStep,
      LoaderComponent
    },
    props: {
      steps: Array,
      results: Array,
      identifier: String
    },
    data (){
      return {
        activeStep: 1,
        showLoader: false,
        UserSelectionStore: UserSelectionStore.data        
      }
    },
    beforeMount() {
      // Populate steps with column classes for rendering
      for(var i=0; i < this.steps.length; i++) {
        this.steps[i].columnClasses = this.getColumnClasses(this.steps[i].columnFormat);
      }            
    },
    mounted() {
      this.$emit('can-continue', {value: true});
    },
    watch: {
      activeStep: function(newValue, oldValue){                
        if (newValue > oldValue) {
          let jsExpressionVars = '';
          let hashVars = [];
          let lastPage = !!(this.steps.length === oldValue);
          let identifierString = 'identifier=' + encodeURIComponent(this.identifier);

          UserSelectionStore.data.selectedItems.forEach(function(item){
            jsExpressionVars += 'var ' + item.key + ' = "' + item.value + '";'
            hashVars.push(item.key + '=' + item.value);
          });

          console.info('Selected params: ' + hashVars.join(' '));

          // Track when user goes to step 2
          if(!UserSelectionStore.data.trackedStart){
            UserSelectionStore.methods.trackStart();
            dataLayer.push({
              "event": "konfigurator",
              "eventCategory": "Stepped guide",
              "eventAction": "start",
              "eventLabel": identifierString + '&' + hashVars.join('&'),
              "eventValue": undefined,
              "eventNonInteractive": false
            });
          }

          // Keep track if match was found 
          let forwardResult = false;

          // Note: Changed from forEach to for loop to make sure 
          // every result is matched in correct order
          for (var i=0; i < this.results.length; i++) {            
            let res = this.results[i];
            
            let urlHashParams = '#' + identifierString + '&' + hashVars.join('&');

            // If trying to match against some result
            if(res.match && !forwardResult){              
              let jsExpression = jsExpressionVars;
              jsExpression += 'return (' + he.decode(res.match) + ')';              
              
              try{                
                if(Function(jsExpression)() && res.url){
                  this.showLoader = true;
                  dataLayer.push({
                    "event": "konfigurator",
                    "eventCategory": "Stepped guide",
                    "eventAction": "complete",
                    "eventLabel": identifierString + '&' + hashVars.join('&'),
                    "eventValue": undefined,
                    "eventNonInteractive": false
                  });
                  
                  window.location = res.url.replace(/&amp;/g, '&') + urlHashParams;
                  console.log('Forwarding to: ', res.url);
                  forwardResult = true;
                }
              } 
              catch(e){
                console.warn(e);
              }
            }

            // Default result
            if(lastPage && res.default){
              this.showLoader = true;
              dataLayer.push({
                "event": "konfigurator",
                "eventCategory": "Stepped guide",
                "eventAction": "complete",
                "eventLabel": identifierString + '&' + hashVars.join('&'),
                "eventValue": undefined,
                "eventNonInteractive": false
              });
              
              // If we have already changed URL 
              // then don't try to do it again later
              if (!forwardResult) {
                window.location = res.url.replace(/&amp;/g, '&') + urlHashParams;
              }              
            }

          }
        }
      }
    },
    methods: {
      setActiveElement: function(val) {        
        this.activeStep = val;
      }, 
      setColumnFormat: function(val) {
        // Get column format for current step        
        this.columnClasses = this.getColumnClasses(val);          
      }, 
      getColumnClasses: function(columnFormat) {
        // Format classes dependant on settings in setup
        let classNames = 'col-sm-6';

        if (columnFormat) {
          if (columnFormat === 'onecol') {
            classNames += ' col-md-12';
          }          
          else if (columnFormat === 'threecol') {
            classNames += ' col-md-4';
          }          
        }
        return classNames;
      }
    }
  }
</script>