<template>
  <div v-if="activeStep === index"
       class="form-element form-element-infobutton-2 form-element-type-infobutton form-group">
    <div class="custom-info-multiple">
      <h2>{{ step.title }}</h2>
      <p>{{ step.subtitle }}</p>
      <div v-html="step.htmlContent"></div>
      <p>{{ step.textAfter }}</p>
    </div>

    <nav class="form-navigation"
         data-form-navigation="">
      <div class="btn-toolbar"
           role="toolbar">
        <div class="btn-group"
             role="group">
          <span class="previous">
            <div v-if="index > 1">
              <button class="btn btn-cancel"
                      type="button"
                      @click="goBack(index)">{{ step.backLabel || 'Tilbage' }}</button>
            </div>
          </span>
          <span class="next">
            <div>
              <button class="btn btn-primary"
                      type="submit"
                      @click="goForward(index)">{{ step.fwdLabel || 'Næste' }}</button>
            </div>
          </span>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  name: 'InfoStep',
  props: {
    step: Object,
    activeStep: Number,
    index: Number,
    total: Number
  },
  methods: {
    goBack(index) {
      this.setActiveElement(index - 1);
    },
    goForward(index) {
      this.setActiveElement(index + 1);
    },
    setActiveElement(val) {
      this.$emit('setActiveElement', val);
    }
  }
}
</script>