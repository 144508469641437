<template>
  <fieldset>

    <StepperComponent 
      :steps="boliusStepperSteps"
      :results="boliusStepperResults"
      :identifier="boliusStepperIdentifier"
      />

  </fieldset>
</template>

<script>
import StepperComponent from './components/StepperComponent.vue';
  
const boliusStepperSteps = global.boliusStepperSteps || [];
const boliusStepperResults = global.boliusStepperResults || [];
const boliusStepperIdentifier = global.boliusStepperIdentifier || '';

export default {
  name: 'App',
  props: {
  },
  data(){
    return {
      boliusStepperSteps,
      boliusStepperResults,
      boliusStepperIdentifier
    }
  },
  components: {
    StepperComponent
  }
};
</script>

<style>

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>