const UserSelectionStore = {

  data: {
    selectedItems: [],
    trackedStart: false, // To prevent tracking start too many times
    email: null,
  },
  methods: {
    trackStart(){
      UserSelectionStore.data.trackedStart = true;
    },
    addSelection(key, value){
      let keyExists = false;
      let keyIndex = 0;

      UserSelectionStore.data.selectedItems.forEach((item, index) => {
        if(item.key === key){
          keyExists = true;
          keyIndex = index;
        }
      })

      if(keyExists){
        // Update existing item
        UserSelectionStore.data.selectedItems[keyIndex] = {key, value};
      } else {
        // New item
        UserSelectionStore.data.selectedItems.push({key, value});
      }
    },
    addEmail(value) {
      UserSelectionStore.data.email = {value};
    }
  }
};


export default UserSelectionStore;